// Variables
@import "../../assets/css/vars";

.table-responsive {
    border-radius: 7px;
}

table {
    font: $small-size-reg;

    thead {
        th {
            background-color: $blue !important;
            border-color: $blue !important;
        }
    }

    tbody {
        color: $dark-blue-one;
        font: $small-size-bold;

        td {
            a {
                background-color: $dark-blue-one;
                color: $white !important;
                padding: 6px 21px;
                border-radius: 26px;
                font-size: 12px;

                &.view {
                    color: #575757 !important;
                    background-color: #c7c7c7;
                }
            }
        }
    }
}

@media (max-width: 500px) {

    table,
    thead,
    tbody,
    tr,
    td,
    th {
        display: block;
    }
    .timer-container{
        display: flex;
        flex-direction: column;
        text-align: left;
        // margin-right: 80%;
        float: left;
        
        }
        
        .current-timer{
            background-color: black;
            text-align: center;
            font: $small-size-bold;
            // font-family: monospace;
            color: cornsilk;
            position: fixed;
          
            font-size: 20px;
            margin: auto;
            line-height: 3.3;
            width: 50px;
            height: 50px;
            border: 1px solid;
            border-radius: 100%;
            margin-bottom: 40%;
           
        
        }
        .timer-controls{
            display: none;
            justify-content: space-evenly;
            
        }
}

.questions {
    padding: 50px 0;

    .q_item {
        padding: 25px;
        border: 2px solid #cccccc;
        border-radius: 6px;
        margin-bottom: 30px;

        .index {
            font: $tiny-size-bold;
            background-color: $blue;
            color: #fff;
            padding: 2px 7px;
            border-radius: 5px;
            display: inline-block;
            margin-bottom: 5px;
        }
        
        .score {
            font: $tiny-size-bold;
            background-color: $dark-blue-two;
            color: #fff;
            padding: 2px 7px;
            border-radius: 5px;
            margin-bottom: 5px;
            float: left;
        }

        .question-name {
            font: $large-size-black;
        }

        .answer {
            color: $dark-blue-one;
            font: $small-size-bold;

            .radio {
                display: flex;
                align-items: center;
                margin-bottom: 11px;

                input[type="radio"] {
                    width: 17px;
                    height: 17px;
                    margin-left: 5px;
                }

                label {
                    margin-bottom: 0;
                }
            }

            .note {
                display: flex;
                background-color: #dcdcdc;
                color: #7c7c7c;
                border-radius: 5px;
                // align-items: center;
                padding: 10px;
                font: $tiny-size-bold;
                margin-bottom: 10px;
                margin-top: 10px;

                span {
                    background-color: #7c7c7c;
                    color: #fff;
                    padding: 1px 5px;
                    border-radius: 5px;
                    margin-left: 6px;
                }

                p {
                    margin-bottom: 0;
                }
            }
        }

        .error {
            color: $warningTxt;
            background-color: $warning;
            padding: 10px;
            font: $small-size-sbold;
            border-radius: 4px;
        }
    }

    .show-score {
        width: 100%;
        text-align: center;
        display: block;
        padding: 11px 0;
        background-color: $blue;
        color: $white;
        border-radius: 5px;
        font: $small-size-bold;
    }
}

input[type="text"],
select {
    outline-color: $blue;
    width: 100%;
    height: 60px;
    border-radius: 7px;
    border: 1px solid $dark-blue-two;
    padding: 5px 20px;  
    font: $small-size-bold;
    color: $dark-blue-two;

    &::placeholder {
        color: $dark-blue-two;
    }
}

select {
    margin-bottom: 20px;
}

.loading {
    background-color: $dark-blue-two !important;
}

.message {
    padding: 18px 0px;
    border-radius: 7px;
    text-align: center;
    font: $small-size-bold;
    margin-top: 20px;

    &.warning {
        background-color: $warning;
        color: $warningTxt;
    }

    &.success {
        background-color: $success;
        color: $successTxt;
    }
}

.timer-container{
    display: flex;
    flex-direction: column;
    text-align: left;
    // margin-right: 80%;
    float: left;
    
    }
    
    .current-timer{
        background-color: black;
        text-align: center;
        font: $small-size-bold;
        // font-family: monospace;
        color: cornsilk;
        position: fixed;
      
        font-size: 30px;
        margin: auto;
        line-height: 3.3;
        width: 100px;
        height: 100px;
        border: 1px solid;
        border-radius: 100%;
        margin-bottom: 40%;
       
    
    }
    .timer-controls{
        display: none;
        justify-content: space-evenly;
        
    }